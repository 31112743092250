body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1D1B1C;
  width: "100%";
  height: "100%";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h3 , h2, h1, h4 {
  color: aliceblue;
}



@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-underline {
  @apply underline;
  text-decoration-thickness: 2px; /* Définir l'épaisseur du soulignement */
  text-underline-offset: 5px;    /* Définir le décalage du soulignement */
}